import React, {useEffect, useState} from 'react';
import {ScrollMenu} from 'react-horizontal-scrolling-menu';

import StrapiAPI from "../../data/strapi";
import VideoThumb from '../videoThumb/videoThumb'

const FILTER_TOP = 'top'
const FILTER_NEW = 'new'

function VideoList(props) {
    const [videos, setVideos] = useState([]);

    const {
        gameId,
        exclude = false,
        filter = FILTER_NEW, // default filter
        type = false,
        videos: hasVideos
    } = props;

    useEffect(() => {
        if (hasVideos) {
            setVideos(hasVideos)
        } else {
            if (filter === FILTER_TOP) {
                StrapiAPI.getGameVideosTop(gameId)
                    .then((response) => {
                        setVideos(response.data);
                    })
            } else if (filter === FILTER_NEW) {
                StrapiAPI.getGameVideosNew(gameId)
                    .then((response) => {
                        setVideos(response.data);
                    })
            }
        }
    }, [filter, gameId])

    if (videos.length === 0) {
        return (
            <div className='flex flex-col py-2 overflow-hidden h-72 items-center '>
                <div className='flex flex-col space-x-1.5 mt-1 flex-nowrap overflow-x-scroll'>
                    <div className='text-center w-screen align-middle'>No videos found</div>
                </div>
            </div>
        );
    }

    return (
        <ScrollMenu
            wrapperClassName='mt-2 py-2 h-72 relative border-b-1 border-zinc-400'
            apiRef={props.apiRef}
        >
            {videos.map((video, index) => {
                const {attributes, attributes: {video_id}} = video
                if (exclude && exclude === video_id) {
                    return null
                }

                if (type && type !== attributes.type) {
                    return null
                }

                return (
                    <VideoThumb key={`${video_id}${index}`} {...attributes} index={index}/>
                )
            })
            }
        </ScrollMenu>
    );
}

export default VideoList;

