import React from 'react'
import Image from 'next/future/image'
import PropTypes from 'prop-types'

function GameThumb(props) {
    const {game, gameChange, index, type, selectedGame} = props

    const {imgSquare = false, name, thumbnail, backers = ''} = game

    const isCF = (type === 'bgg_crowdfunding')
    const isHOT = (type === 'bgg_hotnessXX')

    const iActive = game.id === selectedGame

    return (
        <div className={`w-32 h-50 sm:w-46 sm:h-64 mr-3 ${iActive ? 'border-b-6 border-yellow-300' : ''}  cursor-pointer`} onClick={() => (gameChange(game))}>
            {isHOT && <div className='h-0 mt-0.5 hot-index'>{index+1}</div>}
            <div className='overflow-hidden w-full h-32 sm:h-46 border-zinc-500 border'>
                <Image
                    src={imgSquare ? imgSquare : thumbnail}
                    alt={name}
                    loading='lazy'
                    width={220}
                    height={210}
                />
            </div>
            <div className={'w-full h-16 bg-zinc-900 text-slate-50 px-2.5 py-2'}>
                <h5 className='text-sm line-clamp-2'>{name}</h5>
                {isCF && <div className='text-xxs h-1 mt-0.5'>{backers} Backers</div>}
            </div>

        </div>
    )
}

GameThumb.propTypes = {
    game: PropTypes.object.isRequired,
    gameChange: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired
}

export default GameThumb
