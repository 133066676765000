import React from 'react'

function Module(props) {
    return (
        <div className='flex flex-col h-auto mx-1 mb-8 mt-1 relative py-1'>
            {props.children}
        </div>
    )
}

export default Module

