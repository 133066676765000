import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'
import Link from "next/link";

import VideoList from '../videoList/videoList'
import GameList from '../gameList/gameList'

import {slugifyGame} from "../../utils/slug";
import {ChevronLeftIcon, ChevronRightIcon} from "@heroicons/react/solid";

const CategoryGames = (props) => {
    const [selectedGame, setSelectedGame] = useState(false)
    const [selectedFilter, setSelectedFilter] = useState('new')
    const {items, type} = props

    const scrollVisibilityApiType = React.ContextType;

    const apiRefVideos = React.useRef(scrollVisibilityApiType);

    // 1 or more video
    const cleanedGamesList = items.filter(g => (g.video_count || g.video_count !== 0))

    useEffect(() => {
        setSelectedGame(cleanedGamesList[0])
    }, [])

    if (cleanedGamesList.length === 0) {
        return null
    }

    return (
        <React.Fragment>

            <div className='flex flex-row justify-between'>
                <h2 className='text-xl font-bold pl-2 mt-3 mb-0.5 '>{props.title}</h2>
                <div className={'flex flex-row pt-2 hidden'}>
                    <ChevronLeftIcon className={'h-8 mr-1 cursor-pointer hidden sm:flex'} onClick={() => {
                    }}/>
                    <ChevronRightIcon className={'h-8 ml-1 cursor-pointer hidden sm:flex'} onClick={() => {
                    }}/>
                </div>
            </div>
            {cleanedGamesList &&
                <GameList
                    type={type}
                    gameList={cleanedGamesList}
                    selectedGame={selectedGame.id}
                    gameChange={(game) => {
                        setSelectedGame(game)
                        setSelectedFilter('new')
                    }}
                />}
            <div className='flex flex-row justify-between text-right -mb-1 mt-3 ml-2'>
                <div>
                    <span className={'text-sm font-bold mr-4 hidden sm:inline'}>Videos</span>
                    <div className='btn-group'>
                        <button
                            className={`btn btn-sm btn-secondary text-sm border-gray-300 ${selectedFilter === 'new' ? 'btn-active' : 'border-1'}`}
                            onClick={() => setSelectedFilter('new')}>Recent
                        </button>
                        <button
                            className={`btn btn-sm btn-secondary text-sm border-gray-300 ${selectedFilter === 'top' ? 'btn-active' : 'border-1'}`}
                            onClick={() => setSelectedFilter('top')}>Top
                        </button>
                    </div>
                </div>

                <div className='flex flex-row justify-start'>
                    <div className='btn btn-sm btn-primary mr-5'><Link
                        href={`/games/${slugifyGame(selectedGame.id, selectedGame.name)}`}>Visit game page</Link></div>
                    <ChevronLeftIcon className={'h-8 mr-1 cursor-pointer hidden sm:flex'} onClick={() => {
                        apiRefVideos.current.scrollPrev()
                    }}/>
                    <ChevronRightIcon className={'h-8 ml-1 cursor-pointer hidden sm:flex'} onClick={() => {
                        apiRefVideos.current.scrollNext()
                    }}/>
                </div>
            </div>
            {selectedGame && <VideoList gameId={selectedGame.id} filter={selectedFilter} apiRef={apiRefVideos}/>}
        </React.Fragment>
    )
}

CategoryGames.propTypes = {
    items: PropTypes.array.isRequired,
    type: PropTypes.string.isRequired,
};

export default CategoryGames

