import React from 'react'
import PropTypes from 'prop-types'
import {ScrollMenu} from 'react-horizontal-scrolling-menu';

import GameThumb from '../gameThumb/gameThumb'

function GameList(props) {
    const {gameList, gameChange, type, selectedGame, apiRefGames} = props

    return (
        <ScrollMenu
            wrapperClassName='mt-2 h-auto pt-0.5 pb-3 h-auto relative'
            apiRef={apiRefGames}
        >
            {gameList.map((game, index) => {
                const {id} = game
                return (<GameThumb selectedGame={selectedGame} type={type} key={id + index} index={index}
                                   gameChange={gameChange} game={game}/>)
            })
            }
        </ScrollMenu>
    )
}

GameList.propTypes = {
    gameList: PropTypes.array.isRequired,
    gameChange: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired
}

export default GameList

